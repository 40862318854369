import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";
import {NotificationService} from "../notifications/notification.service";
import {BaseResponse} from "../openband/openband.service";
import {PreferencesService} from "../preferences/preferences.service";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    public router: Router,
    private notifyService: NotificationService,
    private preferencesService: PreferencesService,
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(
      req.clone({headers: req.headers.append('Authorization', "Bearer " + this.preferencesService.appPreferences.token)})
    )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status == 401) {
            this.router.navigateByUrl("/auth")
          }
          try {
            (error.error as BaseResponse).errors.forEach(x => {
              this.notifyService.showError(x.message)
            });
            return throwError(error)
          } catch (e) {
            return throwError(error)
          }
        })
      )
  }
}
