import {environment} from "../../../environments/environment";

export class UrlUtils {

    static getProdServer() {
        // return 'http://localhost:8443/api';
        // return `https://fractal.mosplay.ru/api`;
        // return `https://systems.openband.ru/api/v1`;
        // return `https://systems-stage.openband.ru/api/v1`;
        // return environment.apiUrl
        return `http://192.168.31.192:8444/api/v1`;
    }
}
