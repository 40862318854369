import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UrlUtils} from '../utils/url-utils';
import {NotificationService} from "../notifications/notification.service";
import {Event, Participant} from "../../domain/models";

@Injectable({
  providedIn: 'root'
})
export class OpenbandService {

  private baseUrl = UrlUtils.getProdServer();
  private SLASH = '/';
  private EVENTS = 'events';
  private PARTICIPANTS = 'punches';
  private URI_EVENTS = this.baseUrl + this.SLASH + this.EVENTS;
  private URI_PARTICIPANTS = this.baseUrl + this.SLASH + this.PARTICIPANTS;

  constructor(
    private http: HttpClient,
    private notifyService: NotificationService
  ) {
  }

  //Events
  getEvents(): Observable<Event[]> {
    return this.http.get<Event[]>(this.URI_EVENTS)
  }

  getEvent(id: string): Observable<Event> {
    return this.http.get<Event>(this.URI_EVENTS + this.SLASH + id)
  }

  //Participants
  getParticipants(eventId: string): Observable<Participant[]> {
    return this.http.get<Participant[]>(this.URI_EVENTS + this.SLASH + eventId + this.SLASH + "participants")
  }

  updateParticipant(participant: Participant): Observable<Participant> {
    return this.http.patch<Participant>(this.URI_PARTICIPANTS + this.SLASH + participant.id, participant)
  }

  // createPunch(punch: Punch): Observable<string> {
  //   return this.http.post<string>(this.URI_PUNCHES, [punch])
  // }

}

export class BaseResponse {
  response: any;
  errors: BaseError[];
}

export class BaseError {
  code: string;
  message: string;
  details: string;
}

export class MessageResponse {
  message: string;
}
