export class Participant {
  id: string;
  eventId: string;
  bib: string;
  tag: string;
  name: string;
  surname: string;
  club: string;
  phone: string;
  dateOfBirth: string;
  gender: string;
  category: string;
  event: string;
  uin: string;
}

export class Event {
  id: string;
  name: string;
  visible: string;

  constructor(
    id,
    name,
    visible
  ) {
    this.id = id;
    this.name = name;
    this.visible = visible;
  }
}

export class AppPreferences {
  token: string;
}
