import {Injectable} from '@angular/core';
import {AppPreferences} from "../../domain/models";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  public appPreferences: AppPreferences = new AppPreferences();

  prefName = "app_prefs"

  constructor() {
    if (environment.isStage) this.prefName = "app_name"; else this.prefName = "app_name_stage"
    console.log(environment.isStage)
    console.log(this.prefName)
    this.appPreferences = JSON.parse(localStorage.getItem(this.prefName))
    if (this.appPreferences == null) this.setAppPreferences(new AppPreferences())
    this.reloadPreferences()
  }

  public setAppPreferences(prefs: AppPreferences) {
    localStorage.setItem(this.prefName, JSON.stringify(prefs))
    this.appPreferences = prefs;
  }

  public reloadPreferences() {
    this.appPreferences = JSON.parse(localStorage.getItem(this.prefName))
  }

}
