import {Component} from '@angular/core';
import {Event} from "../../domain/models";
import {ActivatedRoute, Router} from "@angular/router";
import {OpenbandService} from "../../services/openband/openband.service";
import {NotificationService} from "../../services/notifications/notification.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Title} from "@angular/platform-browser";
import {PreferencesService} from "../../services/preferences/preferences.service";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent {
  events: Event[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: OpenbandService,
    private notifyService: NotificationService,
    private modalService: NgbModal,
    private titleService: Title,
    private prefs: PreferencesService,
  ) {
    titleService.setTitle(`Мероприятия`)
    route.params.subscribe(params => {
        this.parseRouteParams(params.id.toString())
        // this.searchPriority = params['priority']
      }
    )
    this.generateTestData()
  }

  generateTestData() {
    this.events.push(new Event("1", "Кросс Нации - ЗАО", true))
    this.events.push(new Event("2", "Кросс Нации - ЮВАО", true))
    this.events.push(new Event("3", "Кросс Нации - САО", true))
    this.events.push(new Event("4", "Кросс Нации - ЗелАО", true))
    this.events.push(new Event("5", "Кросс Нации - ЮЗАО", true))
  }

  parseRouteParams(params: string) {
    var splitted = params.split(";")

  }

}
