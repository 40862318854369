import {NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSliderModule} from '@angular/material/slider';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCommonModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HttpInterceptorService} from "./services/interceptors/HttpInterceptorService";
import {ToastrModule} from 'ngx-toastr';
import {LocationStrategy, PathLocationStrategy} from "@angular/common";
import {SortDirective} from "./helpers/sort/sort.directive";
import {FilterListPipe} from "./helpers/pipes/filter-list-by-bib.pipe";
import {ParticipantsComponent} from "./components/participants/participants.component";
import { EventsComponent } from './components/events/events.component';

@NgModule({
  declarations: [
    AppComponent,
    ParticipantsComponent,
    SortDirective,
    FilterListPipe,
    EventsComponent,
  ],
  imports: [
    BrowserModule,
    MatSliderModule,
    MatSidenavModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSliderModule,
    MatCommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    NgbModule,
    FormsModule,
    ToastrModule.forRoot(
      {
        timeOut: 2500,
        positionClass: 'toast-top-right'
      }
    )
  ],
  providers: [
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
