import {Pipe, PipeTransform} from '@angular/core';
import {Participant} from "../../components/participants/participants.component";

@Pipe({
  name: 'filterList'
})
export class FilterListPipe implements PipeTransform {

  transform(items: Participant[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter(participant => {
      return participant.bib.includes(searchText) || participant.name.includes(searchText) || participant.surname.includes(searchText);
    });
  }

}
