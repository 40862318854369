import {Component} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NotificationService} from "../../services/notifications/notification.service";
import {ActivatedRoute, Router} from "@angular/router";
import {OpenbandService} from "../../services/openband/openband.service";
import {Title} from "@angular/platform-browser";
import {PreferencesService} from "../../services/preferences/preferences.service";

export interface Participant {
  id: string;
  bib: string;
  tag: string;
  name: string;
  surname: string;
  club: string;
  phone: string;
  dateOfBirth: string;
  category: string;
  event: string;
  city: string;
}

function getRandomInt() {
  return Math.floor(Math.random() * 10000).toString();
}

function makeid() {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < 20) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}


const ELEMENT_DATA: Participant[] = [
  {
    id: makeid(),
    bib: getRandomInt(),
    tag: getRandomInt(),
    name: makeid(),
    surname: makeid(),
    club: makeid(),
    phone: "",
    dateOfBirth: "",
    category: "",
    event: "5km",
    city: ""
  },
  {
    id: makeid(),
    bib: getRandomInt(),
    tag: getRandomInt(),
    name: makeid(),
    surname: makeid(),
    club: makeid(),
    phone: "",
    dateOfBirth: "",
    category: "",
    event: "5km",
    city: ""
  },
  {
    id: makeid(),
    bib: getRandomInt(),
    tag: getRandomInt(),
    name: makeid(),
    surname: makeid(),
    club: makeid(),
    phone: "",
    dateOfBirth: "",
    category: "",
    event: "5km",
    city: ""
  },
  {
    id: makeid(),
    bib: getRandomInt(),
    tag: getRandomInt(),
    name: makeid(),
    surname: makeid(),
    club: makeid(),
    phone: "",
    dateOfBirth: "",
    category: "",
    event: "5km",
    city: ""
  },
  {
    id: makeid(),
    bib: getRandomInt(),
    tag: getRandomInt(),
    name: makeid(),
    surname: makeid(),
    club: makeid(),
    phone: "",
    dateOfBirth: "",
    category: "",
    event: "5km",
    city: ""
  },
  {
    id: makeid(),
    bib: getRandomInt(),
    tag: getRandomInt(),
    name: makeid(),
    surname: makeid(),
    club: makeid(),
    phone: "",
    dateOfBirth: "",
    category: "",
    event: "5km",
    city: ""
  },
  {
    id: makeid(),
    bib: getRandomInt(),
    tag: getRandomInt(),
    name: makeid(),
    surname: makeid(),
    club: makeid(),
    phone: "",
    dateOfBirth: "",
    category: "",
    event: "5km",
    city: ""
  },
  {
    id: makeid(),
    bib: getRandomInt(),
    tag: getRandomInt(),
    name: makeid(),
    surname: makeid(),
    club: makeid(),
    phone: "",
    dateOfBirth: "",
    category: "",
    event: "5km",
    city: ""
  },
  {
    id: makeid(),
    bib: getRandomInt(),
    tag: getRandomInt(),
    name: makeid(),
    surname: makeid(),
    club: makeid(),
    phone: "",
    dateOfBirth: "",
    category: "",
    event: "5km",
    city: ""
  },
  {
    id: makeid(),
    bib: getRandomInt(),
    tag: getRandomInt(),
    name: makeid(),
    surname: makeid(),
    club: makeid(),
    phone: "",
    dateOfBirth: "",
    category: "",
    event: "5km",
    city: ""
  },
  {
    id: makeid(),
    bib: getRandomInt(),
    tag: getRandomInt(),
    name: makeid(),
    surname: makeid(),
    club: makeid(),
    phone: "",
    dateOfBirth: "",
    category: "",
    event: "5km",
    city: ""
  },
];


export class Sort {
  sortField: SortFields = SortFields.surname;
  sortType: SortTypes = SortTypes.desc;
}

enum SortFields {
  bib,
  surname,
}

enum SortTypes {
  asc,
  desc,
}

@Component({
  selector: 'app-participants',
  templateUrl: './participants.component.html',
  styleUrls: ['./participants.component.scss']
})
export class ParticipantsComponent {

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  participants = ELEMENT_DATA;

  searchQuery: string = "";

  currentSort = new Sort();
  participantToEdit: Participant;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: OpenbandService,
    private notifyService: NotificationService,
    private modalService: NgbModal,
    private titleService: Title,
    private prefs: PreferencesService,
  ) {
    titleService.setTitle(`Участники`)
  }

  sortByBib() {
    this.currentSort.sortField = SortFields.bib
    if (this.currentSort.sortType == SortTypes.asc) this.currentSort.sortType = SortTypes.desc; else this.currentSort.sortType = SortTypes.asc
    this.participants = this.participants.sort((a, b) => {
      if (+a.bib > +b.bib) {
        if (this.currentSort.sortType == SortTypes.asc)
          return 1;
        else
          return -1
      }
      if (+a.bib < +b.bib) {
        if (this.currentSort.sortType == SortTypes.asc)
          return -1;
        else
          return 1
      }
      return 0
    })
  }

  sortBySurname() {
    this.currentSort.sortField = SortFields.surname
    if (this.currentSort.sortType == SortTypes.asc) this.currentSort.sortType = SortTypes.desc; else this.currentSort.sortType = SortTypes.asc
    this.participants = this.participants.sort((a, b) => {
      if (a.surname > b.surname) {
        if (this.currentSort.sortType == SortTypes.asc)
          return 1;
        else
          return -1
      }
      if (a.surname < b.surname) {
        if (this.currentSort.sortType == SortTypes.asc)
          return -1;
        else
          return 1
      }
      return 0
    })
  }

  openEditPunchDialog(content: any, participant: Participant) {
    this.participantToEdit = participant
    this.modalService.open(content,
      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
      }).result.then(
      (result) => {
        this.notifyService.showSuccess("Edited")
        //TODO add updating participant
        // this.api.updatePunch(participant).subscribe(
        //   res => {
        //   },
        //   error => this.notifyService.showError(error.error.message)
        // )
        this.participantToEdit = null
      },
      (reason) => {
        this.participantToEdit = null
      });
  }
}
