<div style="overflow-x:auto;">

  <div style="margin: 10px; width: auto;">
    <input type="text" class="input-bib" placeholder="Введите текст..." [(ngModel)]="searchQuery"
           autofocus>
  </div>

  <div class="table-container">
    <table class="styled-table">
      <thead>
      <tr>
        <th>UUID</th>
        <th>bib <i class="fa fa-sort" style="margin-left: 3px" aria-hidden="true" (click)="sortByBib()"></i></th>
        <th>tag</th>
        <th>surname <i class="fa fa-sort" style="margin-left: 3px" aria-hidden="true" (click)="sortBySurname()"></i>
        </th>
        <th>name</th>
        <th>club</th>
        <th>phone</th>
        <th>dateOfBirth</th>
        <th>category</th>
        <th>event</th>
        <th>city</th>
      </tr>
      </thead>
      <tbody>
      <ng-container
        *ngFor="let state of participants | filterList: searchQuery"
        style="background-color: #000000;">
        <tr (click)="openEditPunchDialog(content2,state)">
          <td>{{state?.id}}</td>
          <td>{{state?.bib}}</td>
          <td>{{state?.tag}}</td>
          <td>{{state?.surname}}</td>
          <td>{{state?.name}}</td>
          <td>{{state?.club}}</td>
          <td>{{state?.phone}}</td>
          <td>{{state?.dateOfBirth}}</td>
          <td>{{state?.category}}</td>
          <td>{{state?.event}}</td>
          <td>{{state?.city}}</td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
</div>

<ng-template #content2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Редактирование участника
    </h4>

    <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">

      <span aria-hidden="true">
        ×
      </span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <h5>
          {{participantToEdit.surname}} {{participantToEdit.name}}
        </h5>
<!--        <label class="label-black">ID</label>-->
<!--        <input name="punch-id" class="form-control" onfocus="blur();" readonly="readonly" style="color: #787878;"-->
<!--               [(ngModel)]="participantToEdit.name" [contentEditable]="false">-->

        <label class="label-black">Номер участника</label>
        <input name="punch-timing-point" class="form-control"
               [(ngModel)]="participantToEdit.bib" autofocus>

        <label class="label-black">Чип</label>
        <input name="punch-bib" class="form-control"
               [(ngModel)]="participantToEdit.tag" autofocus>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Create click')">
      Обновить
    </button>
  </div>
</ng-template>
